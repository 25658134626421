.sidebarContentWrapper{
  display: flex;
  gap:1rem;
  overflow:hidden;
}
.sidebarOpen{
  width:300px;
}
.sideabarClose{
  width:70px;
}
.sidebarOpenContent, .sidebarContentNotAuthenticated{
  width:calc(100% - 300px);
}
.sidebarCloseContent{
  width:calc(100% - 70px);
}
.sidebarOpen .toggleDrawerBtn, .sideabarClose .toggleDrawerBtn{
  right:1rem;
}
.toggleDrawerBtn{
  position: absolute;
  top:1rem;
}
@media screen and (min-width:991px){
.toggleDrawerBtn{
    display: none;
  }
}
@media screen and (max-width:991px){
.sidebarOpen, .sideabarClose {
    position: absolute;
    z-index:10;
    inset:0;
    width:100%;
    transition:all 0.3s ease-in;
  }
  .sideabarClose{
    transform:translateX(-100%);
  }
.sidebarOpenContent, .sidebarCloseContent, .sidebarContentNotAuthenticated{
    width:100%;
  }
}

