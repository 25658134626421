.custom-image-upload {
    border: 1px solid;
    height: 150px;
    width: 32.5%;
    margin-right: 0.8%;
    border-radius: 20px;
    overflow: hidden;
  }
  .custom-image-upload img{
    object-fit: cover;
  }

  .sliderContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 3% 0%;
}