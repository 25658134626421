.custom-btn{
    background-color: #00C463 !important;
    border: none !important;
    height: 42px;
    border-radius: 8px;
    font-size: 18px;

}
.update-btn{
    background-color: rgb(6, 175, 6) !important;
    border: none !important;
    height: 42px;
    border-radius: 8px;
    font-size: 16px;
}
.delete-btn{
    background-color: rgb(241, 37, 37) !important;
    border: none !important;
    height: 42px;
    border-radius: 8px;
    font-size: 16px;

}
.delete-btn:hover{
    background-color: rgb(189, 5, 5) !important;
    border: none !important;

}
.update-btn:hover{
    background-color: rgb(0, 136, 0) !important;
    border: none !important;
    color: #52c463 !important;
}

.custom-selection{
    outline: none;
    /* border: 2px solid #00C463; */
    border: 2px solid #00C463;
    border-radius: 8px;
    /* color: #00C463; */
    color: #00C463;
    
}

.no-data-table-row{

    border-bottom: 1px solid lightgray !important;
}
.no-data-table-content{
    min-height: 69vh;
    max-height: 69vh;
}
.no-data-table-content p{
    color: grey;
    font-weight: 500;
}