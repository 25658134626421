.pagination {
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.pagination-button {
  background-color: #00C463 !important;
  color: white !important;
  padding: 10px 30px !important;
  border-radius: 6px !important;
}

.disabled-btn{
  background-color: rgba(0,196,99, 0.5) !important;
  color: white !important;

}